import { DeviceEdition } from 'common/types'
import { DateTime } from 'luxon'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Device } from 'shared/types/fleet'
import { Serial } from 'shared/types/utils'
import { Deferred } from 'shared/utils/web/deferred'
import { editDevice } from '../../api'
import { DIALOG_CLOSED_REASON, Dialog } from '../../components/Dialog'
import { Hint, Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { DataContext } from '../../DataProvider'
import { Edited } from '../../utils/computeDiff'

export type DeviceResetDialogProps = {
  deferred: Deferred<Edited<DeviceEdition>>
  device: Device
  serial: Serial
}

export const DeviceResetDialog = ({
  device,
  deferred,
  serial,
}: DeviceResetDialogProps) => {
  const { source } = useContext(DataContext)

  const { handleSubmit, formState } = useForm<DeviceEdition>({})

  const handleClose = () => {
    if (!formState.isSubmitting) {
      deferred.reject(DIALOG_CLOSED_REASON)
    }
  }

  return (
    <Dialog
      title={<Title>Réinitialiser le boitier</Title>}
      onClose={handleClose}
    >
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit(async () => {
          const diff = await editDevice(
            serial,
            device,
            { secretId: null },
            source,
            DateTime.now().toMillis(),
          )
          deferred.resolve(diff)
        })}
      >
        <div className="flex max-w-screen-sm flex-col gap-2">
          <Hint className="whitespace-normal">
            Cette action permet au boitier de demander une nouvelle clé de
            sécurité lorsqu'il essayera de se reconnecter. Cette action ne doit
            être réalisée que si le boitier vient d'être flashé.
          </Hint>
          <span>Venez-vous de flasher le boitier ?</span>
        </div>

        <div className="flex items-center justify-end gap-3">
          <Button variant="outline" onClick={handleClose}>
            NON
          </Button>
          <SubmitButton isSubmitting={formState.isSubmitting}>OUI</SubmitButton>
        </div>
      </form>
    </Dialog>
  )
}
