import { DeviceEssential } from 'common/types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Device } from 'shared/types/fleet'
import { FacilityId, Serial } from 'shared/types/utils'
import { getDeviceStatusDisplay, isDeviceRecording } from 'shared/utils/device'
import { roomDisplayName } from 'shared/utils/room'
import { Hint, Text } from './Text'

export const DeviceListItem: React.FC<{ device: Device; serial: string }> = ({
  device,
  serial,
}) => {
  return (
    <div
      className={`flex flex-row items-baseline gap-2 ${isDeviceRecording(device.status) ? '' : 'opacity-50'}`}
    >
      <div className="flex items-baseline gap-2">
        <Text>
          {device.room
            ? roomDisplayName(device.room)
            : getDeviceStatusDisplay(device.status)}
        </Text>
        <Hint>#{device.partsIds.CASE}</Hint>
      </div>
      <code>{serial.replace('00000000', '')}</code>
    </div>
  )
}

export const DeviceEssentialItem: React.FC<{
  device: DeviceEssential
  serial: Serial
  facilityId: FacilityId
  isSelected?: boolean
}> = ({ device, serial, facilityId, isSelected }) => {
  const { room, id, status } = device
  return (
    <NavLink to={`/explorer/${facilityId}/${serial}`}>
      <div
        className={`flex flex-row items-baseline justify-between gap-2 ${isDeviceRecording(status) ? '' : 'opacity-50'} ${
          isSelected
            ? 'bg-secondary text-secondary-foreground'
            : status === 'pending'
              ? 'text-secondary'
              : ''
        }`}
      >
        <Text>
          {room ? roomDisplayName(room) : getDeviceStatusDisplay(status)}
        </Text>
        <div className="flex items-baseline gap-2">
          <Hint>#{id}</Hint>
          <code>{serial.replace('00000000', '')}</code>
        </div>
      </div>
    </NavLink>
  )
}
