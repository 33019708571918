import { NavLink } from 'react-router-dom'
import { TimeRange } from 'shared/types/timeRange'
import { FirebaseKey, Serial } from 'shared/types/utils'
import { roomDisplayName } from 'shared/utils/room'
import { timeRangeString } from 'shared/utils/timeRange'
import { collator } from 'shared/utils/web/collator'
import { Deferred } from 'shared/utils/web/deferred'
import { Dialog, DIALOG_CLOSED_REASON } from '../../components/Dialog'
import { Title } from '../../components/Text'
import { Button } from '../../components/ui/button'
import RoomExitIcon from '../../icons/room-exit.svg?react'

export type DeviceRoomExits = { room: string; roomExitTimeRange: TimeRange }

export type FacilityOptionsDialogProps = {
  deferred: Deferred<void>
  facilityOptions: Record<'roomExitTimeRange', Record<Serial, DeviceRoomExits>>
  facilityId: FirebaseKey
}

export const FacilityOptionsDialog = ({
  deferred,
  facilityOptions,
  facilityId,
}: FacilityOptionsDialogProps) => {
  const handleClose = () => {
    deferred.reject(DIALOG_CLOSED_REASON)
  }

  return (
    <Dialog title={<Title>Options des boitiers</Title>} onClose={handleClose}>
      <div className="flex min-h-40 min-w-96 flex-col justify-between gap-3">
        {Object.entries(facilityOptions ?? {}).map(
          ([optionType, devicesOptions]) => (
            <div
              className="rounded bg-white bg-opacity-10 p-4"
              key={optionType}
            >
              <div className="flex cursor-pointer items-center justify-between">
                <div className="flex gap-2">
                  <RoomExitIcon className="w-8 fill-current" />
                  <Title>{optionType}</Title>
                </div>
              </div>
              <div className="mt-3">
                {Object.entries(devicesOptions)
                  .filter(([_serial, option]) => option)
                  .sort(
                    (
                      [_serialA, { room: roomA }],
                      [_serialB, { room: roomB }],
                    ) => collator.compare(roomA, roomB),
                  )
                  .map(([serial, device], index) => {
                    return (
                      <NavLink
                        key={serial}
                        to={`/explorer/${facilityId}/${serial}`}
                      >
                        <div key={index} className="flex gap-4 px-4">
                          <div className="flex w-full justify-between">
                            <div className="truncate font-bold">
                              {roomDisplayName(device.room)}
                            </div>
                            <div>
                              {timeRangeString(device.roomExitTimeRange)}
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    )
                  })}
              </div>
            </div>
          ),
        )}

        <div className="flex justify-end  gap-3">
          <Button variant="outline" onClick={handleClose}>
            Fermer
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
