import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { dateTimeFromISO } from 'shared/utils/time'
import { Deferred } from 'shared/utils/web/deferred'
import { DataContext } from '../../DataProvider'
import { addOrder } from '../../api'
import { DIALOG_CLOSED_REASON, Dialog } from '../../components/Dialog'
import { Error, Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { Input } from '../../components/ui/input'
import { formCurrentDateTime } from '../../utils/formCurrentDateTime'

export const AddOrderDialog = ({ deferred }: { deferred: Deferred<void> }) => {
  const { source } = useContext(DataContext)

  const { handleSubmit, register, formState } = useForm({
    defaultValues: {
      datetime: formCurrentDateTime(),
      label: '',
      partsCounts: {
        COMPUTER: '0',
        SDCARD: '0',
        MICROPHONE: '0',
        CABLE: '0',
        POWER: '0',
        CASE: '0',
      },
    },
  })

  const handleClose = () => {
    if (!formState.isSubmitting) {
      deferred.reject(DIALOG_CLOSED_REASON)
    }
  }

  return (
    <Dialog title={<Title>Ajouter une commande</Title>} onClose={handleClose}>
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit(async ({ datetime, partsCounts, ...values }) => {
          await addOrder(
            {
              ...values,
              timestamp: dateTimeFromISO(datetime).toMillis(),
              partsCounts: {
                CABLE: parseInt(partsCounts.CABLE, 10),
                CASE: parseInt(partsCounts.CASE, 10),
                COMPUTER: parseInt(partsCounts.COMPUTER, 10),
                MICROPHONE: parseInt(partsCounts.MICROPHONE, 10),
                POWER: parseInt(partsCounts.POWER, 10),
                SDCARD: parseInt(partsCounts.SDCARD, 10),
              },
            },
            source,
            Date.now(),
          )
          deferred.resolve()
        })}
      >
        <div className="flex flex-col gap-2 md:flex-row">
          <div className="flex flex-col gap-2">
            <label>Libellé</label>
            <Input
              {...register('label', {
                required: 'Libellé requis',
              })}
            />
            {formState.errors.label && (
              <Error>{formState.errors.label.message}</Error>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <label>Date</label>
            <Input
              type="datetime-local"
              {...register('datetime', {
                required: 'Date requise',
              })}
            />
            {formState.errors.datetime && (
              <Error>{formState.errors.datetime.message}</Error>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2 md:flex-row">
          <div className="flex flex-col gap-2">
            <label>Ordinateurs</label>
            <Input
              type="number"
              min="0"
              {...register('partsCounts.COMPUTER')}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label>Cartes SD</label>
            <Input type="number" min="0" {...register('partsCounts.SDCARD')} />
          </div>
        </div>

        <div className="flex flex-col gap-2 md:flex-row">
          <div className="flex flex-col gap-2">
            <label>Microphones</label>
            <Input
              type="number"
              min="0"
              {...register('partsCounts.MICROPHONE')}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label>Câbles d'alimentation</label>
            <Input type="number" min="0" {...register('partsCounts.CABLE')} />
          </div>
        </div>

        <div className="flex flex-col gap-2 md:flex-row">
          <div className="flex flex-col gap-2">
            <label>Blocs d'alimentation</label>
            <Input type="number" min="0" {...register('partsCounts.POWER')} />
          </div>
          <div className="flex flex-col gap-2">
            <label>Boitiers</label>
            <Input type="number" min="0" {...register('partsCounts.CASE')} />
          </div>
        </div>
        <div className="flex items-center justify-end gap-3">
          <Button variant="outline" onClick={handleClose}>
            Annuler
          </Button>
          <SubmitButton isSubmitting={formState.isSubmitting}>
            Valider
          </SubmitButton>
        </div>
      </form>{' '}
    </Dialog>
  )
}
