import { useState } from 'react'
import { get, useForm } from 'react-hook-form'
import { categoryString } from 'shared/types/alert'
import { FirebaseKey, Room } from 'shared/types/utils'
import { roomsInRange } from 'shared/utils/room'
import { Select } from '../../components/Select'
import { Error } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { Input } from '../../components/ui/input'
import { FormRegion, FormZone, FormZones } from '../../utils/zones'

export const EMPTY_REGION: FormRegion = {
  category: null,
  timeRange: {
    start: '',
    end: '',
  },
  roomRange: {
    from: '',
    to: '',
  },
}

export const ZoneEditor = ({
  updateZone,
  rooms,
  zones,
  zone,
  zoneId,
  closeEditor,
}: {
  updateZone: (zoneId: FirebaseKey, zone: FormZone) => void
  rooms: Room[]
  zone: FormZone
  zones: FormZones
  zoneId: FirebaseKey
  closeEditor: () => void
}) => {
  const [editableZone, setEditableZone] = useState<FormZone>(zone)

  const {
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormZone>({
    defaultValues: editableZone,
    mode: 'onBlur',
  })

  register(`regions`, {
    validate: (value) => {
      const regionsArr = Object.values(value ?? {})
      return regionsArr.filter((region) => region !== null).length > 0
        ? true
        : 'Une zone doit contenir au moins 1 région'
    },
  })

  function addRegion() {
    const regionId = Math.random().toString(36).slice(2)
    setValue(`regions.${regionId}`, EMPTY_REGION)
    setEditableZone(getValues())
  }

  function deleteRegion(regionKey: FirebaseKey) {
    setValue(`regions.${regionKey}`, null)
    setEditableZone(getValues())
  }

  return (
    <form
      className="flex flex-col gap-12"
      onSubmit={handleSubmit(async (zone) => {
        updateZone(zoneId, zone)
      })}
    >
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-3">
          <label>Zone dédiée aux fériés et weekend ?</label>
          <Input
            type="checkbox"
            {...register('isHoliday', {
              onChange: (e) => {
                const isHoliday = e.target.checked
                setValue(`isHoliday`, isHoliday)
                if (isHoliday) setValue(`holidayZoneId`, '')
                setEditableZone(getValues())
              },
            })}
            className="h-5 w-5"
          />
        </div>

        <div className="flex flex-row items-baseline gap-3">
          <label>Nom</label>
          <Input
            className="w-full"
            placeholder="Nom"
            {...register(`name`, {
              required: 'Nom requis',
              setValueAs: (v) => v.trim(),
            })}
          />
          {get(errors, `name`) && <Error>{get(errors, `name.message`)}</Error>}
        </div>

        <div className="flex flex-row items-baseline gap-3">
          <label>Ordre</label>
          <Input
            className="w-full"
            type="number"
            placeholder="Ordre"
            {...register(`order`, {
              valueAsNumber: true,
              required: 'Ordre requis',
            })}
          />
          {get(errors, 'order') && (
            <Error>{get(errors, `order.message`)}</Error>
          )}
        </div>

        <div className="flex flex-row items-baseline gap-3">
          <label className="whitespace-nowrap">
            Zone utilisée si fériés et weekends
          </label>
          <Select
            className="w-full"
            {...register(`holidayZoneId`, {
              required: false,
              setValueAs: (v) => (v === '' ? null : v),
              disabled: getValues('isHoliday') === true,
            })}
          >
            <option value={''}>
              pas de zone spécifique les fériés et weekends
            </option>
            {Object.entries(zones)
              .filter(([id, zone]) => id !== zoneId && zone.isHoliday)
              .map(([id, zone]) => (
                <option key={id} value={id}>
                  {zone.name}
                </option>
              ))}
          </Select>
        </div>

        <div className="flex flex-row items-center justify-end">
          {get(errors, 'regions') && (
            <Error>{get(errors, `regions.message`)}</Error>
          )}
        </div>
      </div>

      {Object.entries(editableZone.regions ?? {}).map(([regionId, region]) => {
        if (!region) return null

        const formRegion = watch(`regions.${regionId}`)

        return (
          <div key={regionId} className="flex flex-col gap-2">
            <Input
              type="hidden"
              {...register(`regions.${regionId}`, {
                validate: {
                  noEmptyRegion: (region, datas) => {
                    if (region === null) return true

                    const notNullRegions = Object.values(datas.regions).filter(
                      (reg) => reg !== null,
                    )
                    if (notNullRegions.length <= 1) return true

                    return region.roomRange.from ||
                      region.roomRange.to ||
                      region.timeRange.start ||
                      region.timeRange.end ||
                      region.category
                      ? true
                      : "L'un des 3 champs est requis: catégorie, chambres, surveillance"
                  },
                  roomRangeComplete: (region) => {
                    if (region === null) return true
                    if (!region.roomRange.from && !region.roomRange.to)
                      return true
                    if (region.roomRange.from && region.roomRange.to)
                      return true
                    return 'Champs "chambres" incomplet'
                  },
                  timeRangeComplete: (region) => {
                    if (region === null) return true
                    if (!region.timeRange.start && !region.timeRange.end)
                      return true
                    if (region.timeRange.start && region.timeRange.end)
                      return true
                    return 'Champs "surveillance" incomplet'
                  },
                },
              })}
            />

            <div className="flex w-full flex-row items-baseline gap-3">
              <label>Catégorie</label>
              <Select
                className="w-full"
                {...register(`regions.${regionId}.category`, {
                  required: false,
                  setValueAs: (v) => (v === '' ? null : v),
                })}
              >
                <option value={''}>Toutes</option>
                {Object.entries(categoryString).map(([option, text]) => (
                  <option key={option} value={option}>
                    {text}
                  </option>
                ))}
              </Select>
            </div>

            <div className="flex flex-row items-baseline gap-3">
              <span>Chambres&nbsp;de</span>
              <Input
                className="w-full"
                {...register(`regions.${regionId}.roomRange.from`)}
              />
              {get(errors, `regions.${regionId}.roomRange.from`) && (
                <Error>
                  {get(errors, `regions.${regionId}.roomRange.from.message`)}
                </Error>
              )}
              <span>à</span>
              <Input
                className="w-full"
                {...register(`regions.${regionId}.roomRange.to`)}
              />
              {get(errors, `regions.${regionId}.roomRange.to`) && (
                <Error>
                  {get(errors, `regions.${regionId}.roomRange.to.message`)}
                </Error>
              )}
            </div>

            <div className="flex flex-row items-baseline gap-1">
              <div>Surveillance&nbsp;de</div>
              <Input
                className="w-full"
                type="time"
                {...register(`regions.${regionId}.timeRange.start`)}
              />
              {get(errors, `regions.${regionId}.timeRange.start`) && (
                <Error>
                  {get(errors, `regions.${regionId}.timeRange.start.message`)}
                </Error>
              )}
              <span>à</span>
              <Input
                className="w-full"
                type="time"
                {...register(`regions.${regionId}.timeRange.end`)}
              />
              {get(errors, `regions.${regionId}.timeRange.end`) && (
                <Error>
                  {get(errors, `regions.${regionId}.timeRange.end.message`)}
                </Error>
              )}
            </div>

            {get(errors, `regions.${regionId}`) && (
              <div className="flex justify-end">
                <Error>{get(errors, `regions.${regionId}.message`)}</Error>
              </div>
            )}

            <div className="flex items-center justify-end gap-3">
              <div>{roomsInRange(rooms, formRegion?.roomRange)}</div>
              <Button
                type="button"
                onClick={() => {
                  deleteRegion(regionId)
                }}
              >
                Supprimer
              </Button>
            </div>
          </div>
        )
      })}

      <div className="flex flex-row flex-wrap items-center justify-end gap-2">
        <Button variant="outline" onClick={() => closeEditor()}>
          Annuler
        </Button>
        <Button disabled={isSubmitting} onClick={() => addRegion()}>
          Ajouter une Région
        </Button>
        <SubmitButton isSubmitting={isSubmitting}>Valider</SubmitButton>
      </div>
    </form>
  )
}
